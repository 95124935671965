@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300;0,400;0,500;0,600;1,400&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,600;0,700;1,300;1,400;1,600&display=swap');

// Theme Colors
$farmshare-green: #006f35ff;
$farmshare-yellow: #ffb659ff;
$light-green: #9ee493ff;
$nyanza: #daf7dcff;
$snow: #fcf7f8ff;
$french-gray: #ced3dcff;
$rose-quartz: #aba9c3ff;
$denim: #275dadff;
$bittersweet: #ff715bff;
$lime: #bced09ff;

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import '~bootstrap/scss/functions';

// 2. Include any default variable overrides here
$accordion-button-active-bg: $denim;
$accordion-button-active-color: $snow;

$body-bg: #f6f2ee;
$body-color: #17110c;

$component-active-color: $snow;
$component-active-bg: $farmshare-green;

$font-family-base: 'Figtree';
$font-size-root: 15px;

$headings-font-family: 'Poppins';

$link-color: $denim;
$link-color-dark: $farmshare-yellow;

$nav-link-color: $farmshare-yellow;
$nav-link-color-light: $denim;

$navbar-brand-margin-end: 3rem;
$navbar-light-color: $denim;
$navbar-light-active-color: $farmshare-green;
$navbar-dark-color: $farmshare-yellow;
$navbar-dark-active-color: $farmshare-yellow;

$pagination-color: $denim;

// $tooltip-bg: transparent;

// 3. Include remainder of required Bootstrap stylesheets (including any separate color mode stylesheets)
@import '~bootstrap/scss/variables';

// 4. Include any default map overrides here
$custom-colors: (
  'blue': $denim,
);

$custom-theme-colors: (
  'primary': $farmshare-green,
  'success': $lime,
  'danger': $bittersweet,
  'warning': $farmshare-yellow,
);

$colors: map-merge($colors, $custom-colors);
$theme-colors: map-merge($theme-colors, $custom-theme-colors);

$primary-text-emphasis: $denim;
$primary-bg-subtle: tint-color($denim, 90%);
$primary-border-subtle: tint-color($denim, 10%);

@import '~bootstrap/scss/variables-dark';

// 5. Include remainder of required parts
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/root';

// 6. Optionally include any other parts as needed
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/accordion';
@import '~bootstrap/scss/alert';
@import '~bootstrap/scss/badge';
@import '~bootstrap/scss/breadcrumb';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/card';
@import '~bootstrap/scss/close';
@import '~bootstrap/scss/containers';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/images';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/pagination';
@import '~bootstrap/scss/popover';
@import '~bootstrap/scss/progress';
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/toasts';
@import '~bootstrap/scss/tooltip';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/helpers';

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import '~bootstrap/scss/utilities/api';

.rbt-input {
  background-color: inherit !important;

  &-wrapper {
    align-items: baseline;
  }
}

.rbt-token {
  color: $snow !important;
  background-color: $denim !important;
}
